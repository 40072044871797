[data-amplify-authenticator] [data-amplify-router] {
  border-style: hidden;
  border-radius: 2%;
}
.amplify-button--primary {
  background-color: #748df0;
}
.amplify-button[data-variation="primary"]:hover {
  background-color: #879ced;
  color: white;
}
.amplify-tabs-item[data-state="inactive"] {
  background-color: whitesmoke;
}
.amplify-button--small {
  color: black;
}
.amplify-tabs-item[data-spacing="equal"] {
  color: black;
  font-weight: 300;
  font-size: medium;
  border-style: hidden;
  border-radius: 5%;
}
.amplify-tabs-item[data-spacing="equal"]:hover {
  color: #879ced;
}
.amplify-field-group__control:hover,
.amplify-field-group__control:focus {
  background-color: white;
  border-color: #748df0;
  color: #879ced;
}
.amplify-heading--3 {
  font-size: medium;
  color: black;
  font-weight: 300;
}
.amplify-tabs[data-indicator-position="top"] {
  border-width: 0px;
}
